import { faRight, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageGenerationModelType, MediaPrompt } from 'src/@types/common';
import PredefinedPromptTab from 'src/components/CharacterGeneration/ConfigureAppearance/PromptPickerComponents/PredefinedPromptTab';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import {
  AnimButtonError,
  AnimParamInOutOpacityY100,
  TransSpringPopup,
  TransTweenAnticipate03,
} from 'src/shared/animations';

export type VideoPromptPickerProps = {
  onPick?: ({
    promptId,
    // nudityLevel,
  }: {
    promptId: string;
    // nudityLevel: NudityLevel;
  }) => any;
  model: ImageGenerationModelType;
};

export function VideoPromptPicker({ onPick, model }: VideoPromptPickerProps) {
  const { t } = useTranslation();
  // const { NudityLevels } = useResourcesContext().mediaPrompts!;

  // api params
  const [selectedPrompt, setSelectedPropmpt] = useState<MediaPrompt | null>(
    null
  );

  // errors
  const [showButtonError, setShowButtonError] = useState(false);
  const [buttonError, setButtonError] = useState('');
  const handleError = (error: string) => {
    setButtonError(error);
    setShowButtonError(true);
    setTimeout(() => {
      setShowButtonError(false);
    }, 1000);
  };

  // NudityLevel.Dressed
  // nudity levels
  // const allNudityLevelsRadioItems: RadioItem[] = NudityLevels.map((item) => {
  //   return {
  //     value: item.Title,
  //     label: t(item.Title),
  //     disabled: false,
  //   };
  // }).filter((item) => item.value !== NudityLevel.Nude);

  // const radioNudityLevels = useMemo(() => {
  //   if (selectedPrompt) {
  //     return allNudityLevelsRadioItems.map((nudityLevel) => {
  //       const isAllowed = selectedPrompt.AllowedNudityLevels.includes(
  //         nudityLevel.value as NudityLevel
  //       );
  //       return isAllowed ? nudityLevel : { ...nudityLevel, disabled: true };
  //     });
  //   }
  //   return allNudityLevelsRadioItems;
  // }, [selectedPrompt]);

  // const [nudity, setNudity] = useState<NudityLevel>(
  //   allNudityLevelsRadioItems[0].value as NudityLevel
  // );

  return (
    <motion.div
      className="tw-pb-6 tw-w-full tw-h-full tw-flex tw-flex-col tw-bg-gradient-to-b tw-from-horny-gray-600 tw-to-horny-gray-900 tw-px-6 tw-pt-20 tw-gap-4"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="tw-text-3xl tw-font-bold">
        {t('What do you want to see?')}
      </div>
      <div className="tw-text-lg">{t('select_video_prompt')}</div>
      <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-shadow tw-rounded-md  tw-scrollbar-hide">
        <PredefinedPromptTab
          model={model}
          charGen={false}
          onSelect={(prompt) => {
            setSelectedPropmpt(prompt);
            // setNudity(
            //   prompt?.AllowedNudityLevels.filter(
            //     (nudityLevel) => nudityLevel !== NudityLevel.Nude
            //   )[0]!
            // );
          }}
          isVideo={true}
        />
        {/* <div
          className={cn(
            'tw-px-4 tw-py-4 tw-bg-horny-gray-800 tw-rounded-b-md tw-border-t tw-border-t-horny-gray-600 tw-flex tw-justify-center'
          )}
        >
          <div
            className={cn(
              selectedPrompt === null &&
                'tw-opacity-50 tw-pointer-events-none tw-cursor-default'
            )}
          >
            <ArkRadioGroup
              items={radioNudityLevels}
              onChange={(value) => {
                setNudity(value as NudityLevel);
              }}
            />
          </div>
        </div> */}
      </div>

      <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-relative">
        <AnimatePresence mode="wait">
          {showButtonError && (
            <motion.div
              className="tw-absolute tw-w-full tw-text-center tw-font-bold tw-top-0 tw-pb-1"
              initial="initial"
              animate="in"
              exit="out"
              variants={AnimButtonError}
              transition={TransTweenAnticipate03}
            >
              {buttonError}
            </motion.div>
          )}
        </AnimatePresence>
        <TemptationsButton
          color="red"
          size="lg"
          onClick={() => {
            if (selectedPrompt === null) {
              handleError(t('Select an item to continue'));
              return;
            }
            onPick!({
              promptId: selectedPrompt?.Id,
              // nudityLevel: nudity,
            });
          }}
        >
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
            <FontAwesomeIcon icon={faRight} />
            <div>{t('generate_video_button')}</div>
            <div className="tw-text-xs tw-bg-black tw-bg-opacity-50 tw-rounded-full tw-px-3 tw-py-1 tw-flex tw-items-center tw-gap-1 tw-ml-1">
              <FontAwesomeIcon icon={faVideo} />
              <div>1</div>
            </div>
          </div>
        </TemptationsButton>
      </div>
    </motion.div>
  );
}

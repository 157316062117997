export enum MediaType {
  Photo = "image",
  Video = "video",
  Audio = "audio",
}

export interface GetSingleMediaRequest {
  MediaId: string;
}

export interface GetSingleMediaResponse {
  MediaId: string;
  MediaType: MediaType;
  CoverUrl: string;
  Author: string;
  LikesCount: number;
  Liked: boolean;
  SharedWithCommunity: boolean;
  AddedToMedia: boolean;
  CanShareWithCommunity: boolean;
  BotId: string;
  CanAddToMedia: boolean;
  IsPony: boolean;
  Name: string;
  IsPrivate: boolean;
  PreviewUrl: string;
}

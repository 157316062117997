import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageGenerationModelType, MediaPrompt } from 'src/@types/common';
import { useResourcesContext } from 'src/context/ResourcesContext';
import CategorySelector, {
  CategorySelectorElementProps,
} from './CategorySelector';
import PredefinedPromptElement from './PredefinedPromptElement';

export type PredefinedPromptTabProps = {
  model: ImageGenerationModelType;
  charGen: boolean;
  onSelect: (prompt: MediaPrompt | null) => any;
  isVideo?: boolean;
};

export default function PredefinedPromptTab({
  model,
  charGen,
  onSelect,
  isVideo = false,
}: PredefinedPromptTabProps) {
  const { t } = useTranslation();
  const mediaPrompts = useResourcesContext()
    .mediaPrompts!.Prompts.map((category) => ({
      ...category,
      SubCategories: category.SubCategories.map((subCategory) => ({
        ...subCategory,
        Prompts: subCategory.Prompts.filter(
          (prompt) =>
            prompt.AllowedModels.includes(model) &&
            (charGen ? prompt.IsCharGen === true : true) &&
            (isVideo ? prompt.IsVideo === true : true)
        ),
      })).filter((subCategory) => subCategory.Prompts.length > 0),
    }))
    .filter((category) => category.SubCategories.length > 0);

  const categories: CategorySelectorElementProps[] = mediaPrompts.map((c) => {
    return { title: c.Title, count: c.SubCategories.length };
  });

  const [selectedCategory, setSelectedCategory] = useState(
    mediaPrompts[0].Title
  );

  const [subCategories, setSubCategories] = useState<
    CategorySelectorElementProps[]
  >(
    mediaPrompts[0].SubCategories.map((sc) => {
      return { title: sc.Title, count: sc.Prompts.length };
    })
  );

  const [prompts, setPrompts] = useState<MediaPrompt[]>(
    mediaPrompts[0].SubCategories[0].Prompts
  );

  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);

  const deselectPrompt = () => {
    setSelectedPrompt(null);
    onSelect(null);
  };

  return (
    <>
      <div className="tw-relative tw-bg-horny-gray-900 tw-flex tw-flex-row tw-font-bold  tw-border-b tw-border-horny-gray-600">
        <div className="tw-flex tw-flex-col tw-w-full">
          <CategorySelector
            variant="first-row"
            categories={categories}
            size="base"
            onChange={(value) => {
              setSelectedCategory(value);
              setSubCategories(
                mediaPrompts
                  .find((c) => c.Title === value)
                  ?.SubCategories.map((sc) => ({
                    title: sc.Title,
                    count: sc.Prompts.length,
                  }))!
              );
              setPrompts(
                mediaPrompts
                  .find((c) => c.Title === value)
                  ?.SubCategories.find(
                    (sc) =>
                      sc.Title ===
                      mediaPrompts.find((c) => c.Title === value)
                        ?.SubCategories[0]?.Title!
                  )?.Prompts!
              );
              deselectPrompt();
            }}
          />
          <CategorySelector
            key={selectedCategory}
            variant="second-row"
            size="base"
            categories={subCategories}
            onChange={(value) => {
              setPrompts(
                mediaPrompts
                  .find((c) => c.Title === selectedCategory)
                  ?.SubCategories.find((sc) => sc.Title === value)?.Prompts!
              );
              deselectPrompt();
            }}
          />
        </div>
      </div>
      <div className="tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col  tw-scrollbar-hide">
        <div className="tw-flex-grow tw-overflow-auto tw-bg-horny-gray-600 tw-grid tw-grid-cols-3 tw-gap-2 tw-px-3 tw-py-3 tw-auto-rows-max">
          {prompts.map((prompt) => (
            <PredefinedPromptElement
              key={prompt.Id}
              prompt={prompt}
              isSelected={prompt.Id === selectedPrompt}
              model={model}
              onSelect={() => {
                setSelectedPrompt(prompt.Id);
                onSelect(prompt);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
}

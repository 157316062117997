import { Media, MediaSaveOptions } from '@capacitor-community/media';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleCheck,
  faCommentDots,
  faMinus,
  faPlus,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GetSingleMediaResponse } from 'src/@types/common';
import { ReportMedia } from 'src/components/Report/ReportMedia';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { NoCopyPopup } from 'src/components/Shared/NoCopyPopup/NoCopyPopup';
import { appConfig } from 'src/config/app';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { MediaAPI } from 'src/services/API/MediaAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import {
  blobToBase64,
  copyURLToClibpoard,
  shareData,
  shareNative,
} from 'src/shared/helpers';
import { cn, MediaControls, showAuthorization } from 'src/shared/utils';

export type MediaStatusType = 'publish' | 'unpublish' | 'add' | 'remove';
export type MediaStatusChangeType = {
  MediaId: string;
  status: MediaStatusType;
};
type DownloadState = 'initial' | 'loading' | 'success';
export type MediaDisplayPhotoProps = GetSingleMediaResponse & {
  controls?: MediaControls;
  children: ReactElement;
  childrenType: 'photo' | 'video' | 'audio';
  onChange?: (status: MediaStatusChangeType) => void;
};

export function MediaDisplayWrapper({
  CoverUrl,
  Name,
  AddedToMedia,
  Liked,
  CanShareWithCommunity,
  MediaId,
  SharedWithCommunity,
  Author,
  LikesCount,
  BotId,
  CanAddToMedia,
  IsPony,
  IsPrivate,
  children,
  childrenType,
  onChange,
}: MediaDisplayPhotoProps) {
  const { t } = useTranslation();
  const { isNutaku, nutakuEnv } = useNutakuContext();
  const { user } = useUserContext();
  const [src, setSrc] = useState(CoverUrl);
  const [shareText, setShareText] = useState(t('Share'));
  const mediaUrlToShare = !isNutaku
    ? `${window.location.origin}/media?MediaId=${MediaId}`
    : `${appConfig.nutaku.nutakuShareLink}?BotId=${BotId}&MediaId=${MediaId}&CreatorCode=${user?.CreatorCode}`;
  const shareBlockRef = useRef<HTMLDivElement>(null);
  const communityBlockRef = useRef<HTMLDivElement>(null);
  // const [addBlocksHidden, setAddBlocksHidden] = useState(false);

  const [isSharedWithCommunity, setIsSharedWithCommunity] =
    useState(SharedWithCommunity);
  const [isLiked, setIsLiked] = useState(Liked);
  const [likesCount, setLikesCount] = useState(LikesCount);
  const [addedToMedia, setAddedToMedia] = useState(AddedToMedia);
  const { addModal, removeModal } = useModalContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [reportText, setReportText] = useState(t('Report'));
  const [downloadState, setDownloadState] = useState<DownloadState>('initial');
  const { capture } = useAnalyticsContext();
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSquare, setIsSquare] = useState(false);
  const openedByLink = window.location.pathname.includes('media');
  // const changeBlockHidden = (
  //   e: React.MouseEvent<HTMLDivElement, MouseEvent>
  // ) => {
  //   const { target } = e;
  //   if (
  //     containerRef.current &&
  //     !containerRef.current.contains(target as Node)
  //   ) {
  //     return true;
  //   }
  //   if (
  //     shareBlockRef.current &&
  //     shareBlockRef.current.contains(target as Node)
  //   ) {
  //     return true;
  //   }

  //   if (
  //     communityBlockRef.current &&
  //     communityBlockRef.current.contains(target as Node)
  //   ) {
  //     return true;
  //   }

  //   setAddBlocksHidden(!addBlocksHidden);
  // };

  const androidAlbumName = 'Temptations AI';

  const createAlbumAndroid = async () => {
    let response = await Media.getAlbums();
    let album = response.albums.find((a) => a.name === androidAlbumName);

    if (!album) {
      await Media.createAlbum({ name: androidAlbumName });
      response = await Media.getAlbums();
      album = response.albums.find((a) => a.name === androidAlbumName);
    }

    return album?.identifier;
  };

  const saveToGalleryAndroid = async (url: string) => {
    let opts: MediaSaveOptions = {
      path: url,
      albumIdentifier: await createAlbumAndroid(),
    };
    await Media.savePhoto(opts);
  };

  const saveToDirectoryAndroid = async (filename: string, base64: string) => {
    console.log('saveToDirectoryAndroid');
    const result = await Filesystem.writeFile({
      path: filename,
      data: base64,
      directory: Directory.Documents,
      recursive: true,
    });
  };

  const shareMediaUrl = () => {
    if (nutakuEnv == NutakuEnv.Android) {
      console.log('Sharing with native');
      shareNative({
        title: t('Character') + ' : ' + Name,
        text: t("Check out the image i've generated using Temptations.AI!"),
        url: mediaUrlToShare,
        dialogTitle: t('Share this Character!'),
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    } else if (nutakuEnv == NutakuEnv.Web) {
      addModal({
        children: (
          <NoCopyPopup
            whatCopying={t('sharing link')}
            textToCopy={mediaUrlToShare}
          />
        ),
        className: 'horny-bg-dark_opac',
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    } else {
      shareData({
        url: mediaUrlToShare,
        mediaUrl: CoverUrl,
        name: Name || '',
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    }

    copyURLToClibpoard(mediaUrlToShare);
    setShareText(t('Copied'));
    setTimeout(() => {
      setShareText(t('Shared'));
    }, 2000);
  };

  const shareWithCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    onChange?.({ MediaId, status: 'publish' });
    MediaAPI.shareWithCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(true));
  };

  const removeFromCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    onChange?.({ MediaId, status: 'unpublish' });
    MediaAPI.removeFromCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(false));
  };

  const addToMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    onChange?.({ MediaId, status: 'add' });
    MediaAPI.add({ MediaId }).then(() => setAddedToMedia(true));
  };

  const removeFromMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    onChange?.({ MediaId, status: 'remove' });
    MediaAPI.remove({ MediaId }).then(() => setAddedToMedia(false));
  };

  const like = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.like({
      MediaId,
    }).then(() => {
      setIsLiked(true);
      setLikesCount(likesCount + 1);
    });
  };

  const dislike = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.dislike({
      MediaId,
    }).then(() => {
      setIsLiked(false);
      setLikesCount(likesCount - 1);
    });
  };

  const processSeeMoreClick = () => {
    if (!user) {
      window.location.href = '/?GirlId=' + BotId;
      return;
      //return showAuthorization(addModal);
    }
    if (window.location.pathname.includes('connections')) {
      // ConnectionsAPI.createChat({
      //   BotId,
      // })
      ConnectionsAPI.getChat({
        BotId: BotId,
        StartFrom: 0,
        PageSize: 20,
      }).then(() => {
        goToMedia();
      });
    } else {
      goToMedia();
    }
  };

  const getFilenameFromUrl = (url: string) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const filename = pathname.split('/').pop();
    return filename || 'download.mp3';
  };

  const downloadMedia = () => {
    if (downloadState !== 'initial') {
      return;
    }
    setDownloadState('loading');

    if (childrenType === 'photo' || childrenType === 'video') {
      if (nutakuEnv == NutakuEnv.Android) {
        saveToGalleryAndroid(CoverUrl).finally(() => {
          setDownloadState('success');
          setTimeout(() => {
            setDownloadState('initial');
          }, 2000);
        });
      } else {
        console.log('Downloading media from web', CoverUrl);
        fetch(CoverUrl)
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            const downloadElement = document.createElement('a');
            downloadElement.download = MediaId;
            downloadElement.href = URL.createObjectURL(data);
            downloadElement.click();
          })
          .finally(() => {
            setDownloadState('success');
            setTimeout(() => {
              setDownloadState('initial');
            }, 2000);
          });
      }
    }

    if (childrenType === 'audio') {
      fetch(CoverUrl)
        .then((res) => {
          return res.blob();
        })
        .then(async (blob) => {
          if (nutakuEnv == NutakuEnv.Android) {
            console.log('Saving to Android');
            const base64 = await blobToBase64(blob);
            console.log('base64', base64);
            await saveToDirectoryAndroid(getFilenameFromUrl(CoverUrl), base64);
            console.log('Saved to Android');
          } else {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = getFilenameFromUrl(CoverUrl);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .finally(() => {
          setDownloadState('success');
          setTimeout(() => {
            setDownloadState('initial');
          }, 2000);
        });
    }
  };

  const goToMedia = () => {
    window.location.href = '/connections?ChatId=' + BotId + '&media=community';
  };

  const onReportClick = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    const modal = addModal({
      children: (
        <ReportMedia
          Id={MediaId}
          onReport={() => {
            removeModal(modal);
            setReportText(t('Reported'));
            setTimeout(() => setReportText(t('Report')), 3000);
          }}
        />
      ),
    });
  };

  const isVertical = childrenType === 'photo';

  return (
    <motion.div
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
      className="tw-w-full tw-h-full tw-relative tw-flex tw-flex-col tw-justify-center tw-bg-black tw-bg-opacity-50 tw-content-end tw-z-0"
      ref={containerRef}
    >
      <div
        className={cn(
          'tw-w-full tw-h-full',
          isVertical
            ? 'tw-z-0'
            : 'tw-flex tw-items-center tw-justify-center tw-px-8 tw-pt-20'
        )}
      >
        {children}
      </div>
      {/* MARK: SHARES */}
      <div
        className={cn(
          !isVertical
            ? 'tw-w-full tw-flex tw-justify-center tw-pb-10'
            : 'tw-absolute tw-w-full tw-h-full tw-justify-end tw-items-center tw-flex tw-pointer-events-none tw-z-20'
        )}
      >
        <div
          className={cn(
            'tw-flex tw-gap-6 tw-w-fit tw-h-fit tw-pointer-events-auto tw-z-20',
            !isVertical ? 'tw-flex-row' : 'tw-flex-col tw-me-4'
          )}
        >
          <motion.div
            className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
            whileTap={{ scale: 0.8 }}
          >
            <Icon
              src={isLiked ? IconSrc.AddSuper : IconSrc.AddRed}
              size={IconSize.XL}
              onClick={() => (!isLiked ? like() : dislike())}
            />
            <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
              {likesCount}
            </div>
          </motion.div>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
            onClick={() => downloadMedia()}
          >
            <AnimatePresence mode="wait">
              <motion.div
                className="tw-flex tw-flex-col tw-items-center"
                key={downloadState}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                <div className="tw-w-[32px] tw-h-[32px] tw-flex tw-items-center tw-justify-center">
                  <FontAwesomeIcon
                    icon={
                      downloadState === 'loading'
                        ? faSpinnerThird
                        : downloadState === 'success'
                          ? faCircleCheck
                          : downloadState === 'initial'
                            ? faCloudArrowDown
                            : faCloudArrowDown
                    }
                    className={cn(
                      downloadState === 'loading' && 'tw-animate-spin',
                      'tw-text-4xl'
                    )}
                  />
                </div>

                <div className="tw-text-nowrap horny-text_tiny tw-mt-1 tw-w-16 tw-text-center">
                  {downloadState === 'loading'
                    ? t('Wait...')
                    : downloadState === 'success'
                      ? t('Success!')
                      : downloadState === 'initial'
                        ? t('Download')
                        : t('Download')}
                </div>
              </motion.div>
            </AnimatePresence>
            {/* <Icon
              src={downloading ? IconSrc.Loader : IconSrc.DownloadUpdated}
              size={IconSize.XL}
              className={downloading ? 'horny-spin' : ''}
            />
            <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
              {t('Download')}
            </div> */}
          </motion.div>
          {!IsPrivate && (
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() => shareMediaUrl()}
            >
              <Icon src={IconSrc.ShareBlue} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {shareText}
              </div>
            </motion.div>
          )}
          <motion.div
            whileTap={{ scale: 0.8 }}
            className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
            onClick={() => onReportClick()}
          >
            <Icon src={IconSrc.Report} size={IconSize.XL} />
            <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
              {reportText}
            </div>
          </motion.div>
        </div>
      </div>
      {/* MARK: BOTTOM BLOCK */}
      <div
        className={cn(
          'tw-px-4 tw-py-4 tw-w-full tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-z-20 tw-bg-gradient-to-b tw-from-transparent tw-to-black tw-drop-shadow tw-pb-10'
        )}
        ref={communityBlockRef}
      >
        <div className="tw-flex tw-flex-wrap tw-gap-4 tw-justify-center tw-font-bold">
          <motion.div
            whileTap={{ scale: 0.9 }}
            className={cn(
              'tw-flex tw-flex-row tw-gap-2 tw-items-center',
              CanAddToMedia
                ? 'tw-cursor-pointer'
                : 'tw-pointer-events-none tw-opacity-75'
            )}
            onClick={() => {
              !addedToMedia ? addToMedia() : removeFromMedia();
            }}
          >
            <FontAwesomeIcon
              icon={!addedToMedia ? faPlus : faMinus}
              className="tw-text-2xl"
            />
            <div className="tw-text-nowrap">
              {!addedToMedia ? t('Add to media') : t('Remove from media')}
            </div>
          </motion.div>

          <motion.div
            whileTap={{ scale: 0.9 }}
            className={cn(
              'tw-flex tw-flex-row tw-gap-2 tw-items-center',
              CanShareWithCommunity && user && !IsPrivate
                ? 'tw-cursor-pointer'
                : 'tw-pointer-events-none tw-opacity-50'
            )}
            onClick={() => {
              !isSharedWithCommunity
                ? shareWithCommunity()
                : removeFromCommunity();
            }}
          >
            <FontAwesomeIcon
              icon={!isSharedWithCommunity ? faPlus : faMinus}
              className="tw-text-2xl"
            />
            <div className="tw-text-nowrap">
              {!isSharedWithCommunity
                ? t('Share with Сommunity')
                : t('Remove from Сommunity')}
            </div>
          </motion.div>
        </div>

        <div className="tw-text-center tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-center">
          <div className="tw-opacity-50">{t('Author')}:</div>
          <div className="tw-font-bold">@{Author || 'anonymous'}</div>
        </div>

        {openedByLink && (
          <div
            className="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-gap-2 tw-justify-center tw-font-bold"
            onClick={() => processSeeMoreClick()}
          >
            <FontAwesomeIcon icon={faCommentDots} />
            <div>{t('Chat with this character')}</div>
          </div>
        )}

        {IsPony && (
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-w-full tw-justify-center tw-text-center tw-opacity-50 tw-text-xs">
            <Trans i18nKey="photoCreatedWith">
              <span>Photo created with</span>
              <a
                href="https://purplesmart.ai/"
                target="_blank"
                className="tw-underline tw-underline-offset-2"
              >
                Pony Diffusion
              </a>
            </Trans>
          </div>
        )}
      </div>
    </motion.div>
  );
}

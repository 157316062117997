import { faPlay, faSquareArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { Video } from 'src/components/Chat/ChatMessage/chat-video';
import { MediaStatusChangeType } from 'src/components/Media/Shared/MediaDisplay/MediaDisplayWrapper';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { MediaAPI, MediaType } from 'src/services/API/MediaAPI';
import { replaceUserName } from 'src/shared/helpers';
import { getMediaDisplay } from 'src/shared/utils';
import AudioPlayer from '../../Audio/AudioPlayer';

export function MediaPreviewInListVideo({
  image,
  mediaId,
  onChange,
}: {
  image: string;
  mediaId: string;
  onChange: (status: MediaStatusChangeType) => void;
}) {
  const { capture } = useAnalyticsContext();
  const { addModal } = useModalContext();
  const openFullscreen = () => {
    MediaAPI.getSingleMedia({ MediaId: mediaId }).then(({ data }) => {
      capture({
        event: 'page_open',
        data: {
          page_name: 'Media_fullscreen',
          source: 'media',
          bot_id: data.BotId,
        },
      });
      addModal({
        children: (
          <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
            {getMediaDisplay(data, MediaType.Video, onChange)(data)}
          </div>
        ),
        className: 'tw-bg-black tw-bg-opacity-75',
        bg: '',
      });
    });
  };
  return (
    <motion.button
      whileTap={{ scale: 0.85 }}
      className="tw-aspect-square tw-relative tw-cursor-pointer relative"
      onClick={openFullscreen}
    >
      <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50">
        <FontAwesomeIcon icon={faPlay} size="3x" />
      </div>
      <img src={image} className="tw-w-full tw-h-full tw-object-cover" />
    </motion.button>
  );
}
export type ChatMediaPreviewProps = {
  PreviewUrl: string;
  MediaId: string;
  MediaType: MediaType;
  onChange?: (status: MediaStatusChangeType) => void;
  description?: string;
};
export function ChatMediaPreview({
  PreviewUrl,
  MediaId,
  MediaType: type,
  onChange,
  description,
}: ChatMediaPreviewProps) {
  const { addModal } = useModalContext();

  const src = PreviewUrl + '?height=512';
  const { user } = useUserContext();

  const { capture } = useAnalyticsContext();

  const openFullscreen = () => {
    MediaAPI.getSingleMedia({ MediaId }).then(({ data }) => {
      capture({
        event: 'page_open',
        data: {
          page_name: 'Media_fullscreen',
          source: 'media',
          bot_id: data.BotId,
        },
      });
      addModal({
        children: (
          <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
            {getMediaDisplay(data, type, onChange)(data)}
          </div>
        ),
        className: 'tw-bg-black tw-bg-opacity-75',
        bg: '',
      });
    });
  };

  return (
    <>
      {type === MediaType.Audio && (
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer">
            <AudioPlayer url={src} />
            <motion.div
              whileTap={{ scale: 0.85 }}
              className="tw-text-2xl tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-aspect-square"
              onClick={openFullscreen}
            >
              <FontAwesomeIcon icon={faSquareArrowUpRight} />
            </motion.div>
          </div>
          {description && (
            <div className="tw-text-sm tw-opacity-50 tw-w-full tw-leading-tight">
              {replaceUserName(description, user!)}
            </div>
          )}
        </div>
      )}
      {type === MediaType.Photo && (
        <motion.div
          whileTap={{ scale: 0.85 }}
          className="tw-relative tw-cursor-pointer"
          onClick={() => {
            openFullscreen();
          }}
        >
          <img
            src={src}
            className="tw-rounded-md tw-w-48"
            onClick={() => {
              MediaAPI.getSingleMedia({ MediaId }).then(({ data }) => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Media_fullscreen',
                    source: 'media',
                    bot_id: data.BotId,
                  },
                });
              });
            }}
          />
        </motion.div>
      )}
      {type === MediaType.Video && (
        <motion.div
          whileTap={{ scale: 0.85 }}
          className="tw-relative tw-cursor-pointer"
          onClick={() => {
            openFullscreen();
          }}
        >
          <Video src={src} />
        </motion.div>
      )}
    </>
  );
}

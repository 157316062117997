import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ReportCharacter } from 'src/components/Report/ReportCharacter';
import 'src/components/Shared/tw-base.css';
import { useModalContext } from 'src/context/Modal.Context';
import { ConnectionAPIModel } from 'src/services/API/ConnectionsAPI';

export type ChatNamesProps = Pick<
  ConnectionAPIModel,
  'Author' | 'Name' | 'BotId'
>;
export function ChatNames({ Name, Author, BotId }: ChatNamesProps) {
  const { t } = useTranslation();
  const { addModal, removeModal } = useModalContext();
  const onReportClick = () => {
    const modal = addModal({
      children: (
        <ReportCharacter
          Id={BotId}
          onReport={() => {
            removeModal(modal);
          }}
        />
      ),
    });
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-flex tw-items-center tw-justify-start tw-gap-3">
        <div className="tw-font-bold tw-text-2xl tw-leading-none tw-line-clamp-1">{Name}</div>
        <motion.div
          whileTap={{ scale: 0.85 }}
          onClick={() => onReportClick()}
          className="tw-bg-gradient-to-b tw-from-horny-gray-300 tw-to-horny-gray-600  tw-rounded tw-text-sm tw-px-2 tw-py-1 tw-leading-none tw-shadow-bezel tw-cursor-pointer"
        >
          <FontAwesomeIcon icon={faFlag} size="xs" />
        </motion.div>
      </div>
      <div className="tw-flex tw-gap-1 tw-items-center tw-font-bold tw-text-xs">
        <div>{t('Author')}:</div>
        <div className="tw-opacity-75">@{Author}</div>
      </div>
    </div>
  );
}

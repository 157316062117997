import { appConfig } from 'src/config/app';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
export const prices = {
  [SubscriptionLevel.Friend]: '4.00',
  [SubscriptionLevel.Boyfriend]: '15.00',
  [SubscriptionLevel.Daddy]: '20.00',
  [SubscriptionLevel.Boss]: '40.00',
  [SubscriptionLevel.Master]: '70.00',
  [SubscriptionLevel.God]: '120.00',
};

export const pricesYear = {
  [SubscriptionLevel.Friend]: '16.00',
  [SubscriptionLevel.Boyfriend]: '50.00',
  [SubscriptionLevel.Daddy]: '60.00',
  [SubscriptionLevel.Boss]: '96.00',
  [SubscriptionLevel.Master]: '160.00',
  [SubscriptionLevel.God]: '240.00',
};

export const addTrafficJunkyTag = async (
  value: number,
  level: SubscriptionLevel | string
) => {
  return new Promise((res) => res(true));

  const id = {
    [SubscriptionLevel.Friend]: '1000469601',
    [SubscriptionLevel.Boyfriend]: '1000462131',
    [SubscriptionLevel.Daddy]: '1000462141',
    [SubscriptionLevel.Boss]: '1000462151',
    [SubscriptionLevel.Master]: '1000462151',
  }[level];
  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.id = id + '_cpa_testing';
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);

    image.src = `https://ads.trafficjunky.net/ct?a=${id}&member_id=1006336301&cb=${Date.now()}&cti=${Date.now()}&ctv=${value}&ctd=${level}`;
    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addTrafficJunkyReg = async (id: string) => {
  return new Promise((res) => res(true));

  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.id = 1000462111 + '_cpa_testing';
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);

    image.src = `https://ads.trafficjunky.net/ct?a=${1000462111}&member_id=1006336301&cb=${Date.now()}&cti=${Date.now()}&ctv=${id}&ctd=${'Reg'}`;
    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addTrafficStarTag = async () => {
  return new Promise((res) => res(true));
  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);
    // setTimeout(() => {
    image.src = 'https://tsyndicate.com/api/v2/cpa/132927/pixel.gif';
    // console.log('append');
    // }, 100);

    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addClickMagicReg = async () => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 'a',
      cmc_ref: 'optin',
      vid_info: 'on',
    };
    reappendClickMagic();
    res(true);
  });
};

export const addClickMagicPurchase = async (
  level: SubscriptionLevel,
  isYearly: boolean
) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    const price = !isYearly
      ? prices[level]
      : Math.floor(Number(pricesYear[level]) * 0.7) + '.00';
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 's',
      cmc_ref: 'sale',
      vid_info: 'on',
      cmc_amt: price,
    };
    reappendClickMagic();
    res(true);
  });
};

export const upgradePrices: Record<string, number> = {
  GeneralUp: 35,
  ControlUp: 20,
  AdvancedUp: 20,
  MemoryUp: 20,
};

export const upgradePricesYear: Record<string, number> = {
  GeneralUp: 70,
  ControlUp: 45,
  AdvancedUp: 45,
  MemoryUp: 45,
};

export const addClickMagicPurchaseUpgrade = async (
  level: string,
  isYearly: boolean
) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    const price = !isYearly
      ? upgradePrices[level]
      : Math.floor(upgradePricesYear[level] * 0.7);
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 's',
      cmc_ref: 'sale',
      vid_info: 'on',
      cmc_amt: price + '.00',
    };
    reappendClickMagic();
    res(true);
  });
};

export const addClickMagicPurchaseCredits = async (price: number) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 's',
      cmc_ref: 'sale',
      vid_info: 'on',
      cmc_amt: Math.floor(price) + '.00',
    };
    reappendClickMagic();
    res(true);
  });
};

const reappendClickMagic = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.clkmc.com/cmc.js';
  document.head.append(script);
};

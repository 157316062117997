import { AxiosError, AxiosResponse } from 'axios';
import { usePostHog } from 'posthog-js/react';
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { NotificationType } from 'src/components/Layout/Notification/Notification';
// import { appConfig } from 'src/config/app';
// import { agreementStoreKey } from 'src/decorators/Agreement.Decorator';
import { UserAPI, UserAPIModel } from 'src/services/API/UserAPI';
import { Nutaku } from 'src/services/Nutaku/Nutaku';
import {
  WindowMessageEvent,
  WindowMessageGotUserId,
} from 'src/services/Window/Window';
import { getAxiosInstance, getAxiosInstanceV2 } from 'src/services/axios/axios';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
// import {
//   captureMyTracker,
//   captureTrafficJunky,
// } from './AnalyticsContext/AnalyticsContext';
// import {
//   addTrafficJunkyReg,
//   addTrafficStarTag,
// } from './AnalyticsContext/helpersDOM';
import { PostHog } from 'posthog-js';
import { getNutakuUserIdParam } from 'src/shared/helpers';
import UpdateAppOverlay from '../components/Layout/UpdateAppOverlay';
import { useNotificationsContext } from './Notifications.Context';
import { NutakuEnv, useNutakuContext } from './Nutaku.Context';
// import { MainLoader } from 'src/components/Layout/MainLoader';
// import { PostHog } from 'posthog-js';
export type UserContextProps = {
  user: UserAPIModel | null | undefined;
  setUser: (user: UserAPIModel | null) => any;
  logout: () => any;
  // analytics: PostHog | null;
};
export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: (user: UserContextProps['user']) => {},
  logout: () => {},
  // analytics: null,
});
export const UserContextProvider = UserContext.Provider;

export const useUserContext = () => useContext<UserContextProps>(UserContext);

let a = 1;
const supaBaseClientInstance = getSupabaseClient();
export const UserProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const { isNutaku, nutakuEnv, onCompletePayment, onDestroyPayment } =
    useNutakuContext();
  const [user, setUser] = useState<UserContextProps['user']>(undefined);
  const [energy, setEnergy] = useState<number | undefined>(undefined);
  const [messagesLeft, setMessagesLeft] = useState<number | undefined>(
    undefined
  );
  const posthog = usePostHog();
  const [versionError, setVersionError] = useState(false);

  // useEffect(() => {
  //   window.onerror = (e) => {
  //     alert(JSON.stringify(e))
  //   }
  // }, []);
  useEffect(() => {
    if (isNutaku && nutakuEnv === NutakuEnv.Android) {
      Nutaku.addListener('onDestroyPayment', onDestroyPayment);
      Nutaku.addListener('onCompletePayment', (nutakuPaymentEvent) =>
        onCompletePayment(nutakuPaymentEvent).then(() => {
          UserAPI.get().then(({ data }) => {
            setUser(data);
            setEnergy(data.Energy);
            setMessagesLeft(data.MessagesLeft);
          });
        })
      );
    }
  }, []);
  const { addNotification } = useNotificationsContext();

  const setUserFromSupaBase = async () => {
    const supaBaseSession = await supaBaseClient.auth.getSession();
    axiosInstance.defaults.headers.authorization = `Bearer ${supaBaseSession.data.session?.access_token}`;
    UserAPI.get()
      .then(({ data: userResponse }) => setUser(userResponse))
      .catch((e: AxiosError) => {
        if (e.response?.status === 412) {
          setVersionError(true);
        } else {
          throw e;
        }
      });
  };

  useEffect(() => {
    if (isNutaku && nutakuEnv === NutakuEnv.Android) {
      nutakuAndroidAuth(posthog)
        .then(() => setUserFromSupaBase())
        .catch((e) => {
          addNotification({
            message: e.message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
          return;
        });
    }

    if (isNutaku && nutakuEnv === NutakuEnv.Dev) {
      nutakuDevAuth(posthog)
        .then(() => setUserFromSupaBase())
        .catch((e) => {
          addNotification({
            message: e.message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
          return;
        });
    }

    if (isNutaku && nutakuEnv === NutakuEnv.SP) {
      // @ts-ignore
      console.log({ gadgets: window.gadgets });
      nutakuWebAuthSP(posthog, getNutakuUserIdParam()!)
        .then(() => setUserFromSupaBase())
        .catch((e) => {
          addNotification({
            message: e.message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
          return;
        });
    }

    if (isNutaku && nutakuEnv === NutakuEnv.Web) {
      nutakuWebAuth(posthog)
        .then(() => setUserFromSupaBase())
        .catch((e) => {
          addNotification({
            message: e.message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
          return;
        });
    }

    if (!isNutaku) {
      signWebUser(posthog)
        .then((user) => {
          !!user &&
            user.id &&
            posthog.identify(user.id, {
              ...(email ? { email } : {}),
            });
          setUserFromSupaBase();
        })
        .catch((e) => {
          addNotification({
            message: e.message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
        });
    }
  }, []);

  // console.log({user});

  const [supaBaseSignedIn, setSupaBaseSignedIn] = useState<
    string | false | null
  >(null);
  const [isFetching, setIsFetching] = useState(false);
  const [email, setEmail] = useState('');
  // const updateUser = (user: UserContextProps['user']) => setUser(user);
  const supaBaseClient = getSupabaseClient();
  const axiosInstance = getAxiosInstance();

  // useEffect(() => {
  //   if (user && energy !== undefined && energy < user.Energy ) {
  //     setUser({
  //       ...user,
  //       Energy: energy,
  //     });
  //   }
  // }, [!!user, user?.Energy, energy, setUser]);

  // useEffect(() => {
  //   user &&
  //     messagesLeft !== undefined &&
  //     messagesLeft < user.MessagesLeft &&
  //     setUser({
  //       ...user,
  //       MessagesLeft: messagesLeft,
  //     });
  // }, [!!user, user?.MessagesLeft, messagesLeft]);

  const logout = () => {
    supaBaseClient.auth
      .signOut()
      .catch((e) => console.error(e))
      .finally(() => {
        // setUser(null);
        if (nutakuEnv === NutakuEnv.Android) {
          Nutaku.logout();
          // window.location.reload();
        }
      });
  };

  const responseCatcherIsSetUp = useRef(false);
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (res: AxiosResponse<any>) => {
        //  console.log({headers: Object.keys(res.headers)});
        if (res.headers.energy) {
          setUser((prev) => ({
            ...prev!,
            Energy: Number(res.headers.energy),
          }));
          return res;
          // setEnergy(Number(res.headers.energy));
        }
        if (res.headers['messagesleft']) {
          //  setMessagesLeft(Number(res.headers.messagesleft));
          setUser((prev) => ({
            ...prev!,
            MessagesLeft: Number(res.headers.messagesleft),
          }));
          return res;
        }
        return res;
      },
      (error: AxiosError) => {
        throw error;
      }
    );
  }, []);

  supaBaseClient.auth.onAuthStateChange((event, session) => {
    console.log({
      event,
      session,
      token: session && session.access_token,
    });
    if (session && session.access_token) {
      getAxiosInstance().defaults.headers.authorization =
        'Bearer ' + session.access_token;
      getAxiosInstanceV2().defaults.headers.authorization =
        'Bearer ' + session.access_token;
    }
    // if ((event === 'SIGNED_IN' || event === 'INITIAL_SESSION') && session) {
    //   axiosInstance.defaults.headers.Authorization = `Bearer ${session.access_token}`;

    //   // if (supaBaseSignedIn === false) {
    //   //   posthog.capture('sign_in');

    //   //   // window.location.reload();
    //   // }
    //   session.user.email && setEmail(session.user.email);
    //   setSupaBaseSignedIn(session.access_token);
    //   return;
    // }

    // // if (event === 'INITIAL_SESSION' && !session?.user){
    // //    supaBaseClient.auth.refreshSession();
    // // }

    // if (isNutaku && event === 'INITIAL_SESSION' && !session?.user) {
    //   return;
    // }

    // if (isNutaku && event === 'SIGNED_OUT') {
    //    window.location.reload();
    // }

    if (event === 'SIGNED_OUT' && user) {
      window.location.reload();
    }

    // if (
    //   event === 'SIGNED_OUT' ||
    //   (event === 'INITIAL_SESSION' && !session?.user)
    // ) {
    //   setSupaBaseSignedIn(false);
    //   if (user) {
    //       // window.location.reload();
    //   } else {
    //     setUser(null);
    //   }
    return;
    // }
  });

  // useEffect(() => {
  //   if (supaBaseSignedIn && !user && !isFetching) {
  //     setIsFetching(true);
  //     supaBaseClient.auth
  //       .getUser()
  //       .then(async (res) => {
  //         if (res.data && res.data.user) {
  //           // axiosInstance.defaults.headers.Authorization = `Bearer ${supaBaseSignedIn}`;
  //           return await UserAPI.get()
  //             .then(async ({ data }) => {
  //               // posthog.identify(data.UserId, {
  //               //   email: email || null,
  //               // });
  //               // captureMyTracker({
  //               //   type: 'reachGoal',
  //               //   goal: 'mt_login',
  //               //   userid: data.UserId,
  //               // });
  //               // captureMyTracker({ type: 'setUserID', userid: data.UserId });
  //               // posthog.capture('login');

  //               if (['/auth-redirect'].includes(window.location.pathname)) {
  //                 posthog.identify(data.UserId, {
  //                   email: email || null,
  //                 });
  //                 captureMyTracker({
  //                   type: 'reachGoal',
  //                   goal: 'mt_login',
  //                   userid: data.UserId,
  //                 });
  //                 captureMyTracker({ type: 'setUserID', userid: data.UserId });

  //                 posthog.capture('login', {
  //                   premium_status: data.Subscription.Level,
  //                   app_npm_version: appConfig.app.version,
  //                   Library: 'web',
  //                   app_platform: 'web',
  //                   agreement_version:
  //                     localStorage.getItem(agreementStoreKey) || null,
  //                 });

  //                 if (!data.SignupReported) {
  //                   await UserAPI.reportSignup();
  //                   await addTrafficStarTag();

  //                   await addTrafficJunkyReg(data.UserId);
  //                   captureTrafficJunky({
  //                     name: 'Reg',
  //                     type: 'Acquisition',
  //                     value: '0.001',
  //                   });
  //                 }
  //               }
  //               setUser(data);
  //             })
  //             .catch((e) => setUser(null));
  //         } else {
  //           // setSupaBaseSignedIn(false);
  //           await supaBaseClient.auth.signOut();
  //           delete axiosInstance.defaults.headers.Authorization;
  //           setUser(null);
  //         }
  //       })
  //       // .catch((e: AxiosError) => {
  //       //   console.error({e});
  //       //   if (e.response?.status == 404){
  //       //     setSupaBaseSignedIn(false);
  //       //     setUser(null);
  //       //     delete axiosInstance.defaults.headers.Authorization;

  //       //   }
  //       //   throw e;
  //       // })
  //       .finally(() => setIsFetching(false));
  //   }
  // }, [user, supaBaseSignedIn, supaBaseClient]);

  // useEffect(() => {
  //   // console.log({ supaBaseSignedIn, user });
  //   if (supaBaseSignedIn === false && user) {
  //     // delete axiosInstance.defaults.headers.authorization;
  //     setUser(null);
  //     // window.location.reload();
  //   }
  // }, [user, supaBaseSignedIn]);
  // console.log({ isNutaku, nutakuEnv });
  return versionError ? (
    <UpdateAppOverlay />
  ) : (
    <UserContextProvider
      value={{
        user,
        setUser,
        logout,
        // analytics: posthog,
      }}
    >
      {children}
      {/* {children} */}
      {/* {user === undefined ? <MainLoader progressPercent={0}></MainLoader> : children} */}
    </UserContextProvider>
  );
};

const nutakuAndroidAuth = async (posthog: PostHog) => {
  console.log('NUTAKU ANDROID AUTH');
  // const nutakuUserResponse = await Nutaku.getUserId().catch((e) => {
  //   throw new Error(e.message);
  // });

  const authData = await Nutaku.getAuthData();

  // const sessionData = await UserAPI.getSupabaseSessionForNutaku({
  //   nutakuUserId: nutakuUserResponse.value,
  // }).catch((e: AxiosError) => {
  //   throw new Error(e.message);
  // });

  const supabaseSession = await getSupabaseClient()
    .auth.setSession({
      // access_token: sessionData.data.AccessToken,
      // refresh_token: sessionData.data.RefreshToken,
      access_token: authData.token,
      refresh_token: authData.refresh_token,
    })
    .then((resp) => {
      if (resp.error) {
        throw new Error(resp.error.message);
      } else {
        if (!!resp.data.user && resp.data.user.id) {
          posthog.identify(resp.data.user.id, {
            ...(resp.data.user.email ? { email: resp.data.user.email } : {}),
          });
        }
      }
    });

  return true;
};

const nutakuDevAuth = async (posthog: PostHog) => {
  // const nutakuUserResponse = await Nutaku.getUserId().catch((e) => {
  //   throw new Error(e.message);
  // });

  const sessionData = await UserAPI.getSupabaseSessionForNutaku({
    nutakuUserId: '2000002604',
  }).catch((e: AxiosError) => {
    throw new Error(e.message);
  });

  const supabaseSession = await getSupabaseClient()
    .auth.setSession({
      access_token: sessionData.data.AccessToken,
      refresh_token: sessionData.data.RefreshToken,
    })
    .then((resp) => {
      if (resp.error) {
        throw new Error(resp.error.message);
      } else {
        if (!!resp.data.user && resp.data.user.id) {
          posthog.identify(resp.data.user.id, {
            ...(resp.data.user.email ? { email: resp.data.user.email } : {}),
          });
        }
      }
    });

  return true;
};

const nutakuWebAuth = async (posthog: PostHog) => {
  return new Promise((resolve, reject) => {
    window.addEventListener('message', async (event) => {
      console.log({ event });
      const message = event.data;
      let parsedMessage: WindowMessageGotUserId | undefined = undefined;
      try {
        parsedMessage = JSON.parse(message) as WindowMessageGotUserId;
        console.log({ parsedMessage });
      } catch (e) {}
      if (
        parsedMessage &&
        parsedMessage.event === WindowMessageEvent.GotUserId
      ) {
        const nutakuUserResponse = parsedMessage.data;

        // const sessionData = await UserAPI.getSupabaseSessionForNutaku({
        //   nutakuUserId: nutakuUserResponse.nutakuUserId,
        // }).catch((e: AxiosError) => {
        //   throw new Error(e.message);
        // });

        const supabaseSession = await getSupabaseClient()
          .auth.setSession({
            access_token: parsedMessage.data.token,
            refresh_token: parsedMessage.data.refresh_token,
          })
          .then((supabaseResponse) => {
            if (supabaseResponse.error) {
              throw new Error(supabaseResponse.error.message);
            } else {
              if (
                !!supabaseResponse.data.user &&
                supabaseResponse.data.user.id
              ) {
                posthog.identify(supabaseResponse.data.user.id, {
                  ...(supabaseResponse.data.user.email
                    ? { email: supabaseResponse.data.user.email }
                    : {}),
                });
              }
            }
          });

        return resolve(true);
      }
    });
    console.log('messaging');
    window.parent.postMessage(
      JSON.stringify({
        action: 'auth',
      }),
      '*'
    );
    console.log('message sent');
  });
};

const nutakuWebAuthSP = async (posthog: PostHog, nutakuUserId: string) => {
  const sessionData = await UserAPI.getSupabaseSessionForNutaku({
    nutakuUserId,
  }).catch((e: AxiosError) => {
    throw new Error(e.message);
  });

  const supabaseSession = await getSupabaseClient()
    .auth.setSession({
      access_token: sessionData.data.AccessToken,
      refresh_token: sessionData.data.RefreshToken,
    })
    .then((supabaseResponse) => {
      if (supabaseResponse.error) {
        throw new Error(supabaseResponse.error.message);
      } else {
        !!supabaseResponse.data.user &&
          supabaseResponse.data.user.id &&
          posthog.identify(supabaseResponse.data.user.id, {
            ...(supabaseResponse.data.user.email
              ? { email: supabaseResponse.data.user.email }
              : {}),
          });
      }
    });
};

export const signWebUser = async (posthog: PostHog) => {
  const isAuthedUser = await supaBaseClientInstance.auth
    .getUser()
    .then((resp) => {
      console.log({ resp });
      if (resp.data) {
        !!resp.data.user &&
          resp.data.user.id &&
          posthog.identify(resp.data.user.id, {
            ...(resp.data.user.email ? { email: resp.data.user.email } : {}),
          });
      }
      return !!resp.data.user;
    })
    .catch((err) => {
      console.log({ err });
      return false;
    });
  console.log({ isAuthedUser });
  if (!isAuthedUser) {
    return await supaBaseClientInstance.auth
      .signInAnonymously()
      .then((resp) => {
        if (resp.data.user) {
          if (!!resp.data.user && resp.data.user.id) {
            posthog.identify(resp.data.user.id, {
              ...(resp.data.user.email ? { email: resp.data.user.email } : {}),
            });
          }
          return resp.data.user;
        }
      });
    // .catch((e) => console.error('Ahhh error ' + e));
  }
};

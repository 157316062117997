import {
  CharacterAppearancePromptImage,
  ImageGenerationModelType,
} from "./Character";

export type MediaPrompt = {
  Image: CharacterAppearancePromptImage;
  SFWImage: CharacterAppearancePromptImage;
  Title: string;
  Id: string;
  AllowedNudityLevels: NudityLevel[];
  AllowedModels: ImageGenerationModelType[];
  IsCharGen: boolean;
  IsPremium: boolean;
  IsVideo: boolean;
};
export type MediaPromptSubCategory = {
  Title: string;
  Prompts: MediaPrompt[];
  // Image: string;
};
export type MediaPromptsAPIModel = {
  Title: string;
  // Image: string;
  SubCategories: MediaPromptSubCategory[];
};

export enum NudityLevel {
  Nude = "Nude",
  Lingerie = "Lingerie",
  Dressed = "Dressed",
}

export type NudityAPIModel = {
  Title: NudityLevel;
  Image: string;
};

export type PlaceAPIModel = {
  Title: string;
  Prompt: string;
};
export type CustomPromptsCategories = Record<string, CustomPromptsSubCategory>;
export type CustomPromptsSubCategory = Record<string, string[]>;
export type CustomPrompts = Record<
  ImageGenerationModelType,
  CustomPromptsCategories
>;

export type MediaPromptsResponse = {
  NudityLevels: NudityAPIModel[];
  Prompts: MediaPromptsAPIModel[];
  Places: PlaceAPIModel[];
  CustomPrompts: CustomPrompts;
  ImageGenerationModels: ImageGenerationModelRecord[];
  Voices: Voice[];
};

export type Voice = {
  title: string;
  preview_url: string;
  voice_id: string;
};
export type ImageGenerationModelRecord = {
  Title: string;
  Available: boolean;
  Image: string;
  IsPony: boolean;
};

import { forwardRef } from 'react';
import { cn } from 'src/shared/utils';

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  className?: string;
}

export const Video = forwardRef<HTMLVideoElement, VideoProps>(
  ({ src, className, ...props }, ref) => {
    return (
      <video
        ref={ref}
        autoPlay
        muted
        playsInline
        controls={false}
        className={cn('aspect-square', className)}
        preload="auto"
        loop={true}
        src={src}
        poster="https://teasegames.b-cdn.net/teasegames/ui_elements/transparent.png"
        {...props}
      />
    );
  }
);

Video.displayName = 'Video';

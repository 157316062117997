import { ResourcesResponse } from 'src/@types/common';
import { APIBase } from './APIBase';
import {
  CharacterAppearancePromptImage,
  ImageGenerationModelType,
} from './CharacterAPI';
import { MediaType } from './MediaAPI';
import { SubscriptionProvider } from './UserAPI';

export enum SubscriptionLevel {
  Friend = 'Friend',
  Boyfriend = 'Boyfriend',
  Daddy = 'Daddy',
  Boss = 'Boss',
  Master = 'Master',
  God = 'God',
}

export enum Platform {
  Web = 'web',
  Nutaku = 'nutaku',
}

export type MediaRequestPrices = {
  [key in keyof typeof MediaType]: number;
};

export interface ActionPrices {
  CreateBot: number;
}

export type InviteReward = {
  Id: string;
  Title: SubscriptionLevel;
  Points: number;
  Days: number;
  DaysAlias: string;
  Platform: Platform[];
};

export type InvitePrice = {
  Id: string;
  Title: SubscriptionLevel;
  Points: number;
  Days: number;
  DaysAlias: string;
  Platform: Platform[];
};

export type InviteCodeRewards = {
  Type: string;
  Messages: number;
  Energy: number;
};

export interface PricesAPIModel {
  Media: MediaRequestPrices;
  Actions: ActionPrices;
  Subscriptions: SubscriptionPrices;
  NoSubscription: NoSubscription;
  Anon: NoSubscription;
  InviteRewards: InviteReward[];
  InvitePrices: InvitePrice[];
  InviteCodeRewards: InviteCodeRewards;
}

export type SubscriptionProviderConfig = {
  Id: string;
  YearId: string;
};
export type SubscriptionProvidersConfig = {
  [provider in SubscriptionProvider]: SubscriptionProviderConfig;
};

export type NoSubscription = {
  Energy: string;
  Messages: string;
};

export type SubscriptionPricesConfig = {
  Energy: string;
  Messages: string;
  Price: number;
  PriceYear: number;
  Providers: SubscriptionProvidersConfig;
  NutakuPrice: number | null;
  NutakuPriceYear: number | null;
  NutakuItemId: string | null;
  NutakuItemIdYear: string | null;
  NutakuImageUrl: string | null;
  NutakuItemDescription: string | null;
  Platform: Platform[] | null;
};

export type SubscriptionPrices = {
  [key in keyof typeof SubscriptionLevel]: SubscriptionPricesConfig;
};

export type MediaPrompt = {
  Image: CharacterAppearancePromptImage;
  Title: string;
  Id: string;
  AllowedNudityLevels: NudityLevel[];
  AllowedModels: ImageGenerationModelType[];
  IsCharGen: boolean;
  IsPremium: boolean;
  IsVideo: boolean;
};
export type MediaPromptSubCategory = {
  Title: string;
  Prompts: MediaPrompt[];
  // Image: string;
};
export type MediaPromptsAPIModel = {
  Title: string;
  // Image: string;
  SubCategories: MediaPromptSubCategory[];
};

export enum NudityLevel {
  Nude = 'Nude',
  Lingerie = 'Lingerie',
  Dressed = 'Dressed',
}

export type NudityAPIModel = {
  Title: NudityLevel;
  Image: string;
};

export type PlaceAPIModel = {
  Title: string;
  Prompt: string;
};

export type MediaPromptsResponse = {
  NudityLevels: NudityAPIModel[];
  Prompts: MediaPromptsAPIModel[];
  Places: PlaceAPIModel[];
};

export class ResourcesAPI extends APIBase {
  static getPrices() {
    return this.client.get<PricesAPIModel>('getprices');
  }

  static getMediaPrompts() {
    return this.client.get<MediaPromptsResponse>('GetImagePrompts');
  }

  static getResources() {
    return this.clientV2.get<ResourcesResponse>('v1/resources');
  }
}

import { useState } from 'react';
import { Video } from 'src/components/Chat/ChatMessage/chat-video';
import { cn } from 'src/shared/utils';

export function MediaDisplayVideoInner({
  src,
  preview,
}: {
  src: string;
  preview: string;
}) {
  const [isVertical, setIsVertical] = useState(false);

  return (
    <div className="tw-absolute tw-inset-0 tw-grid tw-items-center tw-pointer-events-none">
      <Video
        src={src}
        className={cn('tw-z-10')}
        onLoad={(e) => {
          const target = e.target as HTMLImageElement;
          if (target.naturalHeight > target.naturalWidth) {
            setIsVertical(true);
          }
        }}
      />
      <img
        src={`${preview}?height=512&blur=10`}
        className="tw-object-cover tw-h-full tw-absolute tw-inset-0"
      />
    </div>
  );
}
